'use client';

import Script from 'next/script'
import {usePathname, useSearchParams} from "next/navigation";
import {useCallback, useEffect} from "react";

export const isGoogleAnalyticsEnabled = () => {
  return process.env.NEXT_PUBLIC_ENV !== 'development'
}

export const usePageView = (GA_MEASUREMENT_ID : string, url : string) => {
  return useCallback(() => {
    if (!isGoogleAnalyticsEnabled()) return

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?.gtag?.("config", GA_MEASUREMENT_ID, {
      page_path: url,
    })
  }, [GA_MEASUREMENT_ID, url]);
};

export default function GoogleAnalytics({GA_MEASUREMENT_ID} : {GA_MEASUREMENT_ID : string}) {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const url = pathname + searchParams.toString()
  const recordPageView = usePageView(GA_MEASUREMENT_ID, url)

  useEffect(() => {
    recordPageView()
  }, [recordPageView, url]);

  if (!isGoogleAnalyticsEnabled()) {
    return null
  }

  return (
    <>
      <Script strategy="afterInteractive"
              src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}/>
      <Script id='google-analytics' strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                try {
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('consent', 'default', {
                      'analytics_storage': 'denied'
                  });

                  gtag('config', '${GA_MEASUREMENT_ID}', {
                      page_path: window.location.pathname,
                  });
                } catch (e) {
                  console.error(e)
                }
                `,
              }}
      />
    </>
  )
}
