'use client'
import {useEffect, useState} from "react";
import {getLocalStorage, setLocalStorage} from "../lib/storage-helper";
import {isGoogleAnalyticsEnabled} from "./GoogleAnalytics.client";

console.log({
  env: process.env.NEXT_PUBLIC_ENV,
  ga_enabled: isGoogleAnalyticsEnabled()
})

export default function CookieBanner() {
  const [cookieConsent, setCookieConsent] = useState(false);

  useEffect(() => {
    const storedCookieConsent = getLocalStorage("cookie_consent", null)

    setCookieConsent(storedCookieConsent)
  }, [setCookieConsent])


  useEffect(() => {
    if (!isGoogleAnalyticsEnabled()) return

    const newValue = cookieConsent ? 'granted' : 'denied'

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!window?.gtag) {
      console.log('window.gtag not defined...')
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?.gtag?.("consent", 'update', {
      'analytics_storage': newValue
    });

    setLocalStorage("cookie_consent", cookieConsent)
  }, [cookieConsent]);

  if (!isGoogleAnalyticsEnabled()) return null

  return (
    <div className={`my-10 mx-auto max-w-max md:max-w-screen-sm
                        fixed bottom-0 left-0 right-0
                        flex px-3 md:px-4 py-3 justify-between items-center flex-col sm:flex-row gap-4
                         bg-gray-700 rounded-lg shadow ${cookieConsent != null ? "hidden" : "flex"}`}>

      <div className='text-center'>
        <p className='text-gray-100'>We use cookies on our site.</p>
      </div>


      <div className='flex gap-2'>
        <button className='px-5 py-2 text-gray-300 rounded-md border-gray-900' onClick={() => setCookieConsent(false)}>Decline</button>
        <button className='bg-gray-900 px-5 py-2 text-white rounded-lg' onClick={() => setCookieConsent(true)}>Allow Cookies</button>
      </div>
    </div>
  )
}
