import "client-only";

export function getLocalStorage(key: string, defaultValue:string|number|boolean|object|null){
  const stickyValue = localStorage.getItem(key);

  return (stickyValue !== null && stickyValue !== 'undefined')
    ? JSON.parse(stickyValue)
    : defaultValue;
}

export function setLocalStorage(key: string, value:string|number|boolean|object|null){
  localStorage.setItem(key, JSON.stringify(value));
}
