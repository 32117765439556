import {SiteMetadata} from "@blogs/types";
import {HeaderLogo} from "../components/HeaderLogo";

export const siteMetadata: SiteMetadata = {
  title: 'Is the stock market open today?',
  author: 'Shane Daugherty',
  headerTitle: 'Is the stock market open?',
  description: 'A website to check if the stock market is open or closed today.',
  language: 'en-us',
  theme: 'system', // system, dark or light
  siteUrl: 'https://www.isthestockmarketopen.us',
  siteLogo: '/static/images/logo.png',
  image: '/static/images/avatar.jpeg',
  socialBanner: '/static/images/twitter-card.png',
  email: 'shane@shanedaugherty.com',
  locale: 'en-US',
  IMAGE_PROXY_TEMPLATE: `https://images.isthestockmarketopen.us/extra-secure/rs:fill:$\{width}:$\{height}:0:0/g:ce/sm:1/dpr:\${dpi}/plain/$\{source}`,
  types: [{ name: 'posts' }],
  header: {
    logo: HeaderLogo
  },
  footer: {
    tagline: 'Check if the stock market is open today.',
  },
  analytics: {
    googleAnalyticsId: 'G-LXWYV6H3GY',
  },
  newsletter: {
    provider: 'mailchimp',
  },
  comment: {
    provider: 'disqus',
    disqusConfig: {
      shortname: 'market-open'
    }
  },
}
