import '../css/tailwind.css'
import '../css/prism.css'
import '../css/video.css'

import {siteMetadata} from "../data/siteMetadata";
import {headerNavLinks} from "../data/headerNavLinks";

import {buildAppPage} from "@blogs/pages";
import CookieBanner from "../components/CookiesBanner";

const ExtraComponents = () => (
  <>
    <CookieBanner/>
  </>
)
export default buildAppPage(siteMetadata, headerNavLinks, {
  extraComponents: ExtraComponents
})
